.backgroundCheckStickyButton {
  background-image: url('./images/RentPrepLogo.png');
  background-size: cover;
  background-position: center;
  height: 36px;
  width: 137px;
  top: 51.7%;
  opacity: 1;
  transform: translate3d(50%, -50%, 0) rotate(90deg) translateY(-50%);
  left: -135px;
  position: fixed;
}

.backgroundCheckMainButton {
  background-image: url('./images/RentPrepLogo.png');
  background-size: cover;
  background-position: center;
  height: 75px;
  width: 289px;
  margin-top: -1.5rem;
  opacity: 1;
}

.backgroundCheckMainButtonContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.backgroundCheckTextContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 900;
}